import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseTask, CourtTask, Eviction} from '@ee/common/models';

@Injectable({providedIn: 'root'})
export class CaseTaskService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  saveCaseTask(caseId: string, task: BaseTask): Observable<BaseTask> {
    return this.http.post<BaseTask>(`${this.environment.api_prefix}api/evictions/${caseId}/task`, task);
  }

  updateCaseTask(caseId: string, task: BaseTask): Observable<BaseTask> {
    return this.http.put<BaseTask>(`${this.environment.api_prefix}api/evictions/${caseId}/task`, task);
  }

  resetCourtDate(caseId: string, courtTask: CourtTask): Observable<Eviction> {
    return this.http.put<Eviction>(`${this.environment.api_prefix}api/evictions/${caseId}/reset-court-date`, courtTask);
  }

  completeCaseTask(caseId: string, task: BaseTask): Observable<BaseTask> {
    return this.http.put<BaseTask>(`${this.environment.api_prefix}api/evictions/${caseId}/task/${task.id}/complete`, task);
  }

  modifyCourtTaskJudgement(caseId: string, task: BaseTask): Observable<BaseTask> {
    return this.http.put<BaseTask>(`${this.environment.api_prefix}api/evictions/${caseId}/task/update-judgement`, task);
  }

  deleteCaseTask(caseId: string, taskId: string): Observable<BaseTask> {
    return this.http.delete<BaseTask>(`${this.environment.api_prefix}api/evictions/${caseId}/task/${taskId}`, {});
  }

}
