import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import {BaseTask, Eviction} from '@ee/common/models';
import { ShowAutoClosableAlert } from '../actions/alert.actions';
import {
  CaseTaskDeleted,
  CaseTaskSaved,
  CompleteCaseTask,
  CourtTaskJudgementUpdated,
  DeleteCaseTask,
  ModifyCourtTaskJudgement,
  ResetCourtDate,
  SaveCaseTask,
  SetCaseTasks,
  UpdateCaseTask
} from '../actions/case-tasks.actions';
import {EvictionStepCompleted} from '../actions/eviction.actions';
import {CaseTaskService} from '@ee/common/services';
import {RefreshTaskCount} from '../actions/auth.actions';

@Injectable()
export class CaseTaskEffects {
  saveTask$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveCaseTask),
      switchMap(({ caseId, task }) =>
        this.caseTaskService.saveCaseTask(caseId, task).pipe(
          switchMap((result: BaseTask) => [CaseTaskSaved(caseId, result), RefreshTaskCount(),
            new ShowAutoClosableAlert('Task successfully saved.')]),
          catchError((error: HttpErrorResponse) => {
            console.error(error.message);
            return [RefreshTaskCount(), new ShowAutoClosableAlert('Error saving new task.')];
          })
        )
      )
    )
  );

  updateCaseTask$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateCaseTask),
      switchMap(({ caseId, task }) =>
        this.caseTaskService.updateCaseTask(caseId, task).pipe(
          switchMap((result: BaseTask) => [CaseTaskSaved(caseId, result), RefreshTaskCount(), new ShowAutoClosableAlert('Task changes saved.')]),
          catchError((error: HttpErrorResponse) => {
            console.error(error.message);
            return [RefreshTaskCount(), new ShowAutoClosableAlert('Error updating task.')];
          })
        )
      )
    )
  );

  completeCaseTask$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompleteCaseTask),
      switchMap(({ caseId, task }) =>
        this.caseTaskService.completeCaseTask(caseId, task).pipe(
          switchMap((result: BaseTask) => [CaseTaskSaved(caseId, result), RefreshTaskCount(), new ShowAutoClosableAlert('Task status saved.')]),
          catchError((error: HttpErrorResponse) => {
            console.error(error.message);
            return [new ShowAutoClosableAlert('Error saving changes.')];
          })
        )
      )
    )
  );

  modifyCourtTaskJudgement$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ModifyCourtTaskJudgement),
      switchMap(({ caseId, task }) =>
        this.caseTaskService.modifyCourtTaskJudgement(caseId, task).pipe(
          switchMap((result: BaseTask) => [CourtTaskJudgementUpdated(caseId, result), RefreshTaskCount(), new ShowAutoClosableAlert('Judgement details updated.')]),
          catchError((error: HttpErrorResponse) => {
            console.error(error.message);
            return [new ShowAutoClosableAlert('Error updating task.')];
          })
        )
      )
    )
  );

  deleteCaseTask$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteCaseTask),
      switchMap(({ caseId, taskId }) =>
        this.caseTaskService.deleteCaseTask(caseId, taskId).pipe(
          switchMap((result: BaseTask) => [CaseTaskDeleted(caseId, result), RefreshTaskCount(),
            new ShowAutoClosableAlert('Task successfully deleted.')]),
          catchError((error: HttpErrorResponse) => {
            console.error(error.message);
            return [new ShowAutoClosableAlert('Error deleting task.')];
          })
        )
      )
    )
  );

  resetCourtDate$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetCourtDate),
      switchMap(({ caseId, payload }) =>
        this.caseTaskService.resetCourtDate(caseId, payload)
          .pipe(switchMap((result: Eviction) => [
            new EvictionStepCompleted(result),
            SetCaseTasks(result.tasks),
            RefreshTaskCount(),
            new ShowAutoClosableAlert('Court date successfully rescheduled.')
          ]))
      )
    )
  );

  constructor(private actions$: Actions, private caseTaskService: CaseTaskService) {}
}
