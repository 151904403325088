<div class="auth-container flex flex-row justify-center items-center">
  <div *ngIf="!isLoading && !nameOfUser">
    <h2>Attorney not found.</h2>
  </div>
  <mat-horizontal-stepper [linear]="true" class="mat-elevation-z6 w-[1000px]"
                          *ngIf="!isLoading && nameOfUser">
    <mat-step [stepControl]="activationKeyForm">
      <form [formGroup]="activationKeyForm">
        <div class="flex flex-col justify-around items-center" style="height: 383px;">
          <ng-template matStepLabel>Welcome</ng-template>
          <h1>Welcome to EasyEviction, {{nameOfUser}}!</h1>
          <p>Just a couple questions about your business and you'll be all set!</p>
          <input matInput formControlName="key" required hidden/>
          <mat-error *ngIf="activationKeyForm.get('key').hasError('required')">
            Activation key is invalid.
          </mat-error>
          <button mat-button mat-raised-button color="accent" matStepperNext>Let's Go</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="companyForm">
      <form [formGroup]="companyForm">
        <ng-template matStepLabel>Company Details</ng-template>
        <div class="flex flex-col justify-around items-center">
          <h1>Company Details</h1>
          <p>Your company information will be saved to ensure invoices include your complete address.</p>
        </div>
        <div>
          <div class="flex flex-row justify-center items-start">
            <div class="flex flex-col">
              <ee-address-autocomplete placeholder="Street Address 1" [required]="true"
                                       [formControl]="$any(companyForm.get('street_address_1'))"
                                       (selectionChange)="onAddressSelected($event)">
              </ee-address-autocomplete>

              <mat-form-field>
                <mat-label>Street Address 2</mat-label>
                <input matInput formControlName="street_address_2"/>
                <mat-hint>Optional</mat-hint>
              </mat-form-field>
              <div class="city-state-zip">
                <mat-form-field>
                  <mat-label>City</mat-label>
                  <input matInput formControlName="city" required/>
                  <mat-error *ngIf="companyForm.get('city').hasError('required')">
                    City is required.
                  </mat-error>
                  <mat-hint>Required</mat-hint>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>State</mat-label>
                  <mat-select formControlName="state" name="state" required>
                    <mat-option *ngFor="let state of states | keyvalue" [value]="state.key">
                      {{state.value}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="companyForm.get('state').hasError('required')">
                    State is required.
                  </mat-error>
                  <mat-hint>Required</mat-hint>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Zip Code</mat-label>
                  <input matInput mask="00000" formControlName="zip_code" required/>
                  <mat-error *ngIf="companyForm.get('zip_code').hasError('required')">
                    Zip Code is required.
                  </mat-error>
                  <mat-hint>Required</mat-hint>
                </mat-form-field>
              </div>
              <div class="flex flex-row">
                <mat-form-field class="flex-1">
                  <mat-label>Phone</mat-label>
                  <input matInput mask="(000) 000-0000" formControlName="main_phone_number" required/>
                  <mat-error *ngIf="companyForm.get('main_phone_number').hasError('required')">
                    Phone is required.
                  </mat-error>
                  <mat-hint>Required</mat-hint>
                </mat-form-field>
                <div class="flex-1"></div>
                <div class="flex-1"></div>
              </div>
            </div>
          </div>
          <div class="flex flex-row justify-center items-center">
            <button class="mr-3" mat-button mat-raised-button color="primary" matStepperPrevious>Back</button>
            <button mat-button mat-raised-button color="accent" matStepperNext (click)="submit()"
                    [disabled]="companyForm.invalid">
              Get Started
            </button>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>
