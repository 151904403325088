import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// Layouts
import { LayoutClassicComponent } from './layout-classic/layout-classic.component';

// Components
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { MenuSidenavComponent } from './components/menu-sidenav/menu-sidenav.component';
import { NotificationSidenavComponent } from './components/notification-sidenav/notification-sidenav.component';
import { LanguageMenuComponent } from './components/language-menu/language-menu.component';
import { TopHorizontalMenuComponent } from './components/top-horizontal-menu/top-horizontal-menu.component';
import { LayoutLoaderComponent } from './components/layout-loader/layout-loader.component';
import { UserAgreementDialogComponent } from './components/user-agreement-dialog/user-agreement-dialog.component';
import { SubscriptionBannerComponent } from './components/subscription-banner/subscription-banner.component';

// Directives.
import { ScrollTopDirective } from './directives/portal-scroll-top.directive';

import { ThemeSwitcherMenuComponent } from './components/theme-switcher-menu/theme-switcher-menu.component';
import { LayoutService } from '../core/services/layout.service';
import { CoreModule } from '../core/core.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { StoreModule } from '@ngrx/store';
import { notificationReducer } from '../core/reducers/notification.reducer';
import { appSearchReducer } from '../core/reducers/search.reducer';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LayoutCompactComponent } from './layout-compact/layout-compact.component';
import { VerticalIconMenuComponent } from './components/vertical-icon-menu/vertical-icon-menu.component';
import { MatBadgeModule } from '@angular/material/badge';
import { evictionReducer } from '../core/reducers/eviction.reducer';
import { organizationReducer } from '../core/reducers/organization.reducer';
import { workflowReducer } from '../core/reducers/workflow.reducer';
import { messageReducer } from '../core/reducers/message.reducer';
import { formReducer } from '../core/reducers/form.reducer';
import { billingReducer } from '../core/reducers/billing.reducer';
import { taskReducer } from '../core/reducers/task.reducer';
import { PortalRoutingModule } from './portal-routing.module';
import { PortalComponent } from './portal.component';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EeDefaultTooltipOptions } from '../core/tooltip.options';
import { TagManagerModule } from '@ee/common/tag-manager';
import {EePipesModule} from '@ee/common/pipes';

@NgModule({
  imports: [
    PortalRoutingModule,
    CoreModule.forRoot(),
    RouterModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCardModule,
    MatGridListModule,
    MatTooltipModule,
    MatSidenavModule,
    MatButtonModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatBadgeModule,
    StoreModule.forFeature('notification', notificationReducer),
    StoreModule.forFeature('appSearch', appSearchReducer),
    StoreModule.forFeature('eviction', evictionReducer),
    StoreModule.forFeature('client', organizationReducer),
    StoreModule.forFeature('workflow', workflowReducer),
    StoreModule.forFeature('message', messageReducer),
    StoreModule.forFeature('form', formReducer),
    StoreModule.forFeature('billing', billingReducer),
    StoreModule.forFeature('task', taskReducer),
    SharedComponentsModule,
    TooltipModule.forRoot(EeDefaultTooltipOptions),
    MatDialogModule,
    MatCheckboxModule,
    TagManagerModule,
    EePipesModule
  ],
  exports: [LayoutClassicComponent, MenuSidenavComponent, LayoutCompactComponent],
  declarations: [
    LayoutClassicComponent,
    LayoutCompactComponent,
    UserMenuComponent,
    MenuSidenavComponent,
    NotificationSidenavComponent,
    LanguageMenuComponent,
    TopHorizontalMenuComponent,
    ScrollTopDirective,
    LayoutLoaderComponent,
    ThemeSwitcherMenuComponent,
    VerticalIconMenuComponent,
    PortalComponent,
    UserAgreementDialogComponent,
    SubscriptionBannerComponent
  ],
  providers: [LayoutService]
})
export class PortalModule {}
