import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIService } from './services/ui.service';
import { EnumFilterByPropPipe } from './pipes/enum-filter-by-prop.pipe';
import { AnalyticsService } from './services/analytics.service';
import { SearchPipe } from './pipes/search.pipe';
import { DzDirective } from './directives/dz.directive';
import { UpgradeSubscriptionService } from './services/upgrade-subscription.service';
import { MatButtonLoadingDirective } from './directives/mat-button-loading.directive';
import { ConfirmDialogModule } from '@ee/common/confirm-dialog';
import { ParentChildService } from './services/parent-child.service';
import { BtnCellRendererComponent } from './grid/btn-cell-renderer.component';
import { MatButtonModule } from '@angular/material/button';
import { IconBtnCellRendererComponent } from './grid/icon-btn-cell-renderer.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { ClientSelectionDialogModule } from '@ee/common/client-selection-dialog';
import { environment } from '../../environments/environment';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    ClientSelectionDialogModule.forRoot(environment)
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EnumFilterByPropPipe,
    SearchPipe,
    DzDirective,
    MatButtonLoadingDirective,
    BtnCellRendererComponent,
    IconBtnCellRendererComponent,
    HasPermissionDirective
  ],
  declarations: [
    EnumFilterByPropPipe,
    SearchPipe,
    DzDirective,
    MatButtonLoadingDirective,
    BtnCellRendererComponent,
    IconBtnCellRendererComponent,
    HasPermissionDirective
  ],
  providers: [Title, DatePipe, EnumFilterByPropPipe, SearchPipe, UIService, AnalyticsService, ParentChildService, UpgradeSubscriptionService]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule
    };
  }
}
