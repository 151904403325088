import {UntypedFormBuilder, Validators} from '@angular/forms';
import {FileValidator} from 'ngx-custom-material-file-input';
import {AttachmentType, DocumentType} from '@ee/common/enums';
import {MAX_FILE_UPLOAD_SIZE} from '@ee/common/constants';
import {HtmlPage} from './html-page.model';
import {Auditing} from './auditing.model';
import {County} from './county.model';
import {FormField} from './form-field.model';
import filter from 'lodash-es/filter';

export interface DocumentTemplate extends Auditing {
  id: string;
  name: string;
  description: string;
  disabled: boolean;
  prevent_delete: boolean;
  detailed_claim: boolean;
  state: string;
  path: string;
  advanced_fill: boolean;
  type: AttachmentType;
  form_type: DocumentType;
  clear_missing_fields: boolean;
  generate_multiple_per_tenant: boolean;
  tenant_count: number;
  zip_codes: number[];
  original_form_id: string;
  is_public: boolean;
  pages: HtmlPage[];
  file: any;
  fields: FormField[];
  counties: County[];
}

export function generateDocumentTemplate(fb: UntypedFormBuilder, type?: DocumentType, required = false) {
  const fileValidators: any[] = [FileValidator.maxContentSize(MAX_FILE_UPLOAD_SIZE)];
  if (required) {
    fileValidators.push(Validators.required);
  }

  return fb.group({
    id: null,
    name: ['', Validators.required],
    description: null,
    disabled: false,
    prevent_delete: false,
    detailed_claim: false,
    state: null,
    path: null,
    advanced_fill: false,
    clear_missing_fields: true,
    generate_multiple_per_tenant: false,
    tenant_count: 1,
    type: [null, Validators.required],
    zip_codes: [],
    original_form_id: null,
    counties: fb.array([]),
    pages: fb.array([]),
    file: [undefined, fileValidators],
    fields: fb.array([]),
    form_type: [type, Validators.required]
  });
}

export function processDocumentTemplateSuggestions(forms: DocumentTemplate[]): DocumentTemplateSuggestion[] {
  const documentTemplateSuggestions: DocumentTemplateSuggestion[] = [];
  if (forms.length) {
    const myForms = filter(forms, {is_public: false});
    const publicForms = filter(forms, {is_public: true});

    if (myForms.length) {
      documentTemplateSuggestions.push({
        name: 'My Templates',
        forms: myForms
      });
    }
    if (publicForms.length) {
      documentTemplateSuggestions.push({
        name: 'Public Forms',
        forms: publicForms
      });
    }
  }
  return documentTemplateSuggestions;
}

export interface DocumentTemplateSuggestion {
  name: string;
  forms: DocumentTemplate[];
}
