import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  EmailDetails,
  Invoice,
  InvoicePayment,
  InvoiceResults,
  InvoiceSearch,
  LawPayBankAccount,
  LawPayContact,
  Results, StatementReport
} from '@ee/common/models';
import {IServerSideGetRowsRequest} from '@ag-grid-community/core';
import {InvoiceStatus, PaymentStatus} from '@ee/common/enums';

@Injectable({providedIn: 'root'})
export class InvoiceService {

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  findInvoices(request: IServerSideGetRowsRequest, includeFilters: boolean, fields: string[] = [], statuses: InvoiceStatus[] = [],
    paymentStatuses: PaymentStatus[] = [], pastDueOnly: boolean = false, clientId: string | undefined = undefined):
    Observable<Results<Invoice>> {
    let queryParams = `include-filters=${includeFilters}`;

    if (paymentStatuses.length) {
      queryParams += `&payment-statuses=${paymentStatuses.map(p => p.toUpperCase()).join(',')}`;
    }

    if (statuses.length) {
      queryParams += `&statuses=${statuses.map(s => s.toUpperCase()).join(',')}`;
    }

    if (pastDueOnly) {
      queryParams += '&past-due-only=true';
    }

    if (clientId) {
      queryParams += `&client=${clientId}`;
    }

    if (fields.length) {
      queryParams += `&fields=${fields.join(',')}`;
    }

    return this.http.post<Results<Invoice>>(
      this.environment.api_prefix + `api/invoices/search?${queryParams}`, request);
  }

  getInvoiceDashboard(clientId: string | undefined = undefined): Observable<{[key: string]: any}> {
    const params = {};
    if (clientId) {
      params['client'] = clientId;
    }

    return this.http.get<{[key: string]: any}>(`${this.environment.api_prefix}api/invoices/dashboard-widgets`, { params });
  }

  getInvoicesByClient(orgId: string | undefined): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(`${this.environment.api_prefix}api/organizations/${orgId}/invoices`);
  }

  getCurrentInvoiceNumberForClient(orgId: string): Observable<number> {
    return this.http.get<number>(`${this.environment.api_prefix}api/invoices/current-number/${orgId}`);
  }

  generatePaymentReport(startDate: string, endDate: string): Observable<any> {
    return this.http.get(`${this.environment.api_prefix}api/invoices/payment-report?start-date=${startDate}&end-date=${endDate}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  generateStatementReport(statementReport: StatementReport): Observable<any> {
    return this.http.post(`${this.environment.api_prefix}api/invoices/statement-report`, statementReport, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  search(searchRequest: InvoiceSearch): Observable<InvoiceResults> {
    return this.http.post<InvoiceResults>(`${this.environment.api_prefix}api/search/invoices`, searchRequest);
  }

  getLawPayBankAccounts(): Observable<LawPayBankAccount[]> {
    return this.http.get<LawPayBankAccount[]>(`${this.environment.api_prefix}api/plugins/lawpay-bank-accounts`);
  }

  getLawPayContacts(query: string): Observable<LawPayContact[]> {
    return this.http.get<LawPayContact[]>(`${this.environment.api_prefix}api/plugins/lawpay-contacts?q=${escape(query)}`);
  }

  recordInvoicePayments(invoicePayments: InvoicePayment[]): Observable<Invoice[]> {
    return this.http.post<Invoice[]>(`${this.environment.api_prefix}api/invoices/payments`, invoicePayments);
  }

  sendInvoice(id: string, emailDetails: EmailDetails): Observable<Invoice> {
    return this.http.post<Invoice>(`${this.environment.api_prefix}api/invoices/${id}/send`, emailDetails);
  }

  saveInvoice(payload: Invoice): Observable<Invoice> {
    return this.http.post<Invoice>(`${this.environment.api_prefix}api/invoices`, payload);
  }
}
