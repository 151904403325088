<div class="auth-container registration flex flex-row md:justify-center md:items-center">
  <mat-card appearance="outlined" class="auth-card mat-elevation-z2 w-[1000px] flex flex-col sm:flex-row justify-stretch items-stretch">
    <mat-card-content *ngIf="!isLoading; else loading" class="flex flex-row justify-between flex-1 registration-form">
      <form class="mr-3 flex-1 flex-col" [formGroup]="form" (ngSubmit)="submit()">
        <div class="flex-1 flex flex-col justify-center items-stretch">
          <h3>Welcome to the {{activationDetails?.company_name}} team!</h3>
          <p>
            Please confirm your name, time zone, and set your password to continue to the portal.
          </p>
          <div class="flex flex-row">
            <mat-form-field class="compact mr-2 flex-1">
              <mat-label>First Name</mat-label>
              <input matInput formControlName="first_name">
              <mat-error *ngIf="form.get('first_name').hasError('required')">
                First name is required.
              </mat-error>
            </mat-form-field>
            <mat-form-field class="compact flex-1">
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="last_name">
              <mat-error *ngIf="form.get('last_name').hasError('required')">
                Last name is required.
              </mat-error>
            </mat-form-field>
          </div>
          <mat-form-field class="compact">
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName="password">
            <mat-error *ngIf="form.get('password').hasError('required')">
              Password is required.
            </mat-error>
            <mat-error *ngIf="form.get('password').hasError('pattern')">
              Must be at least 8 characters, contain one lowercase letter, one uppercase letter, and a number.
            </mat-error>
          </mat-form-field>
          <mat-form-field class="compact">
            <mat-label>Time Zone</mat-label>
            <mat-select formControlName="time_zone" required>
              <mat-option *ngFor="let tzName of tzNames" [value]="tzName">
                {{tzName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('time_zone').hasError('required')">
              Timezone is required.
            </mat-error>
          </mat-form-field>
        </div>
        <p class="tos">By confirming below, you agree to our
          <a href="https://easyeviction.com/terms/" target="_blank">
            Terms of Services
          </a>
          and
          <a href="https://easyeviction.com/privacy/" target="_blank">
            Privacy Policy
          </a>.
        </p>
        <div class="justify-end items-end">
          <button mat-button mat-raised-button color="accent" type="submit"
                  [disabled]="isLoading || form.invalid">
            Confirm
          </button>
        </div>
      </form>
      <mat-divider class="mr-3" [vertical]="true"></mat-divider>
      <div class="flex-1 flex flex-col justify-start items-stretch">
        <div class="registration-instruction">
          <div class="logo-image">
            <a href="https://www.easyeviction.com">
              <img class="logo" [src]="'/assets/images/logo.png'" alt="EasyEviction">
            </a>
          </div>
          <p class="align-center"><b>Evictions Made Simple.</b></p>
          <p>
            EasyEviction is here to help you organize your evictions in one, easy-to-use, powerful
            software.
          </p>
          <p>
            <b>Need help?</b> No problem! Simply visit our <a href="https://help.easyeviction.com">help page</a>
            or contact us anytime at <a href="mailto:support@easyeviction.com">support&#64;easyeviction.com</a>.
          </p>
        </div>
      </div>
    </mat-card-content>
    <ng-template #loading>
      <mat-card-content class="registration-form flex flex-row justify-center items-center flex-1">
        <ee-loading message="Loading..."></ee-loading>
      </mat-card-content>
    </ng-template>
  </mat-card>
</div>
