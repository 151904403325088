<div class="auth-container flex flex-row sm:justify-center sm:items-center">
  <div *ngIf="!isLoading && !nameOfUser">
    <h2>Attorney not found.</h2>
  </div>
  <mat-horizontal-stepper [linear]="true" class="w-[1000px] mat-elevation-z6"
                          *ngIf="!isLoading && nameOfUser">
    <mat-step [stepControl]="activationKeyForm">
      <form [formGroup]="activationKeyForm">
        <div class="flex flex-col justify-around items-center" style="height: 383px;">
          <ng-template matStepLabel>Welcome</ng-template>
          <h1>Welcome to EasyEviction, {{nameOfUser}}!</h1>
          <p>Just a couple questions about your business and you'll be all set!</p>
          <input matInput formControlName="key" required hidden/>
          <mat-error *ngIf="activationKeyForm.get('key').hasError('required')">
            Activation key is invalid.
          </mat-error>
          <button mat-button mat-raised-button color="accent" matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="lawFirmForm">
      <form [formGroup]="lawFirmForm">
        <ng-template matStepLabel>Law Firm Details</ng-template>
        <div class="flex flex-col justify-around items-center">
          <h1>Law Firm Details</h1>
          <p>Information will be saved for billing purposes and to ensure invoices include your complete address.</p>
        </div>
        <div>
          <div class="flex flex-row justify-between items-start">
            <div class="flex !flex-col justify-start items-stretch mr-4 w-3/5">

              <ee-address-autocomplete placeholder="Street Address 1" [required]="true"
                                       [formControl]="$any(lawFirmForm.get('street_address_1'))" (selectionChange)="onAddressSelected($event)">
              </ee-address-autocomplete>

              <mat-form-field class="mb-4">
                <mat-label>Street Address 2</mat-label>
                <input matInput formControlName="street_address_2"/>
                <mat-hint>Optional</mat-hint>
              </mat-form-field>
              <div class="city-state-zip mb-4">
                <mat-form-field>
                  <mat-label>City</mat-label>
                  <input matInput formControlName="city" required/>
                  <mat-error *ngIf="lawFirmForm.get('city').hasError('required')">
                    City is required.
                  </mat-error>
                  <mat-hint>Required</mat-hint>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>State</mat-label>
                  <mat-select formControlName="state" name="state" required>
                    <mat-option *ngFor="let state of states | keyvalue" [value]="state.key">
                      {{state.value}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="lawFirmForm.get('state').hasError('required')">
                    State is required.
                  </mat-error>
                  <mat-hint>Required</mat-hint>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Zip Code</mat-label>
                  <input matInput mask="00000" formControlName="zip_code" required/>
                  <mat-error *ngIf="lawFirmForm.get('zip_code').hasError('required')">
                    Zip Code is required.
                  </mat-error>
                  <mat-hint>Required</mat-hint>
                </mat-form-field>
              </div>
              <div class="flex flex-row">
                <mat-form-field class="flex-1">
                  <mat-label>Phone</mat-label>
                  <input matInput mask="(000) 000-0000" formControlName="main_phone_number" required/>
                  <mat-error *ngIf="lawFirmForm.get('main_phone_number').hasError('required')">
                    Phone is required.
                  </mat-error>
                  <mat-hint>Required</mat-hint>
                </mat-form-field>
              </div>
              <div class="flex-1"></div>
              <div class="flex-1"></div>
            </div>
            <div class="w-2/5">
              <mat-card appearance="outlined">
                <mat-card-content>
                  <ee-upload-logo (save)="setLogo($event)" [autoSet]="true"></ee-upload-logo>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <div class="flex flex-row justify-center items-center">
            <button class="mr-3" type="button" mat-button mat-raised-button color="primary" matStepperPrevious>Back</button>
            <button mat-button mat-raised-button color="accent" type="button" matStepperNext [disabled]="lawFirmForm.invalid">
              Next
            </button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="attorneyKeyForm">
      <form [formGroup]="attorneyKeyForm">
        <div class="flex flex-col justify-around items-stretch" style="height: 383px;">
          <ng-template matStepLabel>Get Started</ng-template>
          <div class="flex flex-col justify-center items-center">
            <h1>Let’s help clients find you</h1>
            <p>
              This is your custom client intake page where new or existing clients can submit their evictions.
              Click <a [href]="appProtocol + '://saul-goodman-law-firm.' + appDomain" target="_blank">here</a> for an example.</p>
            <p>
              This is also the key you will provide clients already using the EasyEviction platform so they may add you as
              their attorney.
            </p>
          </div>
          <div class="attorney-key flex flex-row justify-center items-baseline">
            <span>{{appProtocol}}://</span>
              <mat-form-field class="flex-[1_1_300px]">
                <input matInput formControlName="attorney_key" required class="attorney-key"/>
                <mat-error *ngIf="attorneyKeyForm.get('attorney_key').hasError('required')">
                  Law firm identifier is required.
                </mat-error>
                <mat-error *ngIf="attorneyKeyForm.get('attorney_key').hasError('attorneyKey')">
                  Law firm identifier already exists. Please try a different one.
                </mat-error>
                <mat-error *ngIf="attorneyKeyForm.get('attorney_key').hasError('pattern')">
                  Only letters, numbers, and dashes can be used.
                </mat-error>
              </mat-form-field>
            <span>.{{appDomain}}</span>
          </div>
          <div class="flex flex-row justify-center items-center">
            <button class="mr-3" mat-button mat-raised-button color="primary" matStepperPrevious>Back</button>
            <button mat-button mat-raised-button color="accent" matStepperNext (click)="submit()"
                    [disabled]="attorneyKeyForm.invalid || lawFirmForm.invalid || isSaving || disableGetStarted">
              Get Started
            </button>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>
