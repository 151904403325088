import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ReportField} from './report-field.model';
import {InvoiceStatus, ManualPaymentSource, PaymentStatus, PaymentType, SortDirection} from '@ee/common/enums';
import {DateTime} from 'luxon';

export class Search {
  page: number;
  page_size: number;
  sort_direction: SortDirection;
  sort_by: string;
  start_date?: string;
  end_date?: string;
  client_id?: string;
  property_manager_id?: string;
  fields?: ReportField[];
  include_all_results?: boolean;
}

export class EvictionSearch extends Search {
  status: number;
  tags: string[];
  and_tags: boolean;
  tagifyTags: [{value: string}];
}

export class InvoiceSearch extends Search {
  payment_status?: PaymentStatus;
  payment_type?: PaymentType;
  manual_payment_source?: ManualPaymentSource;
  draft_statuses: InvoiceStatus[];
  include_past_due_12_months?: boolean;
}

export function GenerateEvictionSearchForm(fb: UntypedFormBuilder, startDate?: Date, endDate?: Date) {
  return fb.group({
    date_filter_type: 'open_date',
    start_date: [startDate, Validators.required],
    end_date: [endDate, Validators.required],
    tagifyTags: [[]],
    and_tags: false,
    client_id: [''],
    property_manager_id: [''],
    attorney_id: [''],
    status: [0],
    fields: fb.array([]),
    include_all_results: false
  });
}

export function GenerateInvoiceSearchForm(fb: UntypedFormBuilder, startDate?: Date, endDate?: Date) {
  return fb.group({
    start_date: [DateTime.fromJSDate(startDate), Validators.required],
    end_date: [DateTime.fromJSDate(endDate), Validators.required],
    payment_status: [''],
    payment_type: [''],
    manual_payment_source: [''],
    client_id: [''],
    property_manager_id: [''],
    past_due: null,
    include_past_due_12_months: false,
    draft_statuses: [[]],
    fields: fb.array([]),
    include_all_results: false
  });
}
