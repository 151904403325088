import { Routes } from '@angular/router';
import { registerRoute } from './register/register.route';
import { activateAttorneyRoute } from './activate/attorney/activate-attorney.route';
import { activatePropertyManagerRoute } from './activate/property-manager/activate-property-manager.route';
import { activateNewUserRoute } from './activate/new-user/activate-new-user.route';
import { authorizeRoute } from './authorize.route';

const ACCOUNT_ROUTES = [
  registerRoute,
  activateAttorneyRoute,
  activatePropertyManagerRoute,
  activateNewUserRoute,
  authorizeRoute
];

export const accountState: Routes = [
  {
    path: '',
    children: ACCOUNT_ROUTES
  }
];
