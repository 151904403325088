<div class="auth-container registration flex flex-row justify-center items-center">
  <mat-card appearance="outlined" class="auth-card mat-elevation-z2 flex flex-col sm:flex-row w-[1000px] justify-stretch items-stretch">
    <mat-card-content *ngIf="!form.get('type').value" class="registration-type flex flex-col flex-1">
      <div class="logo-image">
        <a href="https://www.easyeviction.com">
          <img class="logo" src="/assets/images/logo.png" alt="EasyEviction">
        </a>
      </div>
      <div>Please select your registration type below.</div>
      <div class="flex flex-row justify-between flex-1">
        <div class="selection-type mr-3 flex flex-col justify-center items-center flex-[1_1_50%]"
             [routerLink]="['/register']" [queryParams]="{ 'type': 'attorney' }">
          <div class="icon-wrapper">
            <mat-icon class="account-type-icon" fontSet="fa" fontIcon="fa-gavel"></mat-icon>
          </div>
          <h2>Register as an Attorney</h2>
        </div>
        <div class="selection-type flex flex-col justify-center items-center flex-[1_1_50%]"
             [routerLink]="['/register']" [queryParams]="{ 'type': 'pm' }">
          <div class="icon-wrapper">
            <mat-icon class="account-type-icon" fontSet="fa" fontIcon="fa-home"></mat-icon>
          </div>
          <h2>Register as a Property Manager/Owner</h2>
        </div>
      </div>
    </mat-card-content>
    <mat-card-content *ngIf="form.get('type').value" class="registration-form !flex flex-1 !flex-row justify-between">
      <form class="p-4 flex-[1_1_50%] flex flex-col" [formGroup]="form" *ngIf="!attorneySignedUp" (ngSubmit)="register()">
        <h3>Create your new {{form.get('type').value === accountTypes.ATTORNEY ? 'Attorney' : 'Property Manager'}}
          account</h3>
        <div class="flex flex-col flex-1 justify-center items-stretch">
          <div formGroupName="user">
            <div class="pb-4 flex flex-row">
              <mat-form-field class="compact mr-2">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="first_name">
              </mat-form-field>
              <mat-form-field class="compact">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="last_name">
              </mat-form-field>
            </div>
            <mat-form-field class="compact pb-4">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email">
              <mat-error *ngIf="form.get('user').get('email').hasError('emailTaken')">
                A user with this email address is already registered.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="pb-4" formGroupName="organization">
            <mat-form-field class="compact">
              <mat-label *ngIf="form.get('type').value === accountTypes.ATTORNEY">Law Firm Name</mat-label>
              <mat-label *ngIf="form.get('type').value === accountTypes.CLIENT">Company Name</mat-label>
              <input matInput formControlName="company_name">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field class="compact">
              <mat-label>Password</mat-label>
              <input matInput type="password" #password formControlName="password">
              <mat-error *ngIf="form.get('password').hasError('pattern')">
                Must be at least 8 characters, contain one lowercase letter,
                one uppercase letter, and a number.
              </mat-error>
            </mat-form-field>
            <ee-password-strength-bar [passwordToCheck]="form.get('password').value"></ee-password-strength-bar>
          </div>
          <div class="pb-4">
            <mat-form-field class="compact">
              <mat-label>Invitation Code</mat-label>
              <input matInput formControlName="invitation_code">
              <mat-error *ngIf="form.get('invitation_code').hasError('codeInvalid')">
                Invalid invitation code.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-checkbox formControlName="tos_policy_confirm">
          <p class="tos">
            I agree to the EasyEviction <a class="underline" href="https://easyeviction.com/terms/">Terms</a>
            and
            <a class="underline" href="https://easyeviction.com/privacy/">Privacy Policy</a>.
          </p>
        </mat-checkbox>
        <div class="justify-end">
          <button mat-button mat-raised-button color="accent" type="submit"
                  [disabled]="form.invalid || isLoading">
            <span *ngIf="form.get('type').value === accountTypes.ATTORNEY">Sign up for free trial</span>
            <span *ngIf="form.get('type').value === accountTypes.CLIENT">Sign up for free account</span>
          </button>
        </div>
      </form>
      <div class="p-4 flex flex-col flex-[1_1_50%] justify-center items-start" *ngIf="attorneySignedUp">
        <strong><p>Your invitation's been sent. Please check your email.</p></strong>
        <div>
          <p>Clinking the link in the invitation will confirm your email address and start your onboarding process.</p>
          <p class="flex flex-row justify-center items-center">
            <span class="email-button flex-row mr-3" (click)="openEmail('https://www.gmail.com')">
              <img class="email-logo"
               src="/assets/images/logos/gmail.png"
               alt="" />
              Gmail
            </span>
            <span class="email-button flex-row" (click)="openEmail('https://www.outlook.com')">
              <img class="email-logo"
                   src="/assets/images/logos/outlook.png"
                   alt="" />
              Outlook
            </span>
          </p>
        </div>
        <p>Can't find the email? Please check your spam folder.</p>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="p-4 flex flex-row flex-[1_1_50%] justify-center">
        <div class="registration-instruction">
          <div class="logo-image flex flex-row justify-center items-center">
            <a href="https://www.easyeviction.com">
              <img class="logo" [src]="'/assets/images/logo.png'" alt="EasyEviction">
            </a>
          </div>
          <div *ngIf="form.get('type').value === accountTypes.ATTORNEY">
            <p class="free-trial">
              <strong>Your 14 day free trial includes:</strong>
            </p>

            <div class="flex flex-col justify-start items-start">
              <div class="flex flex-row justify-start items-center mb-1">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature"><b>No credit card is required during the trial</b></div>
              </div>
              <div class="flex flex-row justify-start items-center mb-1">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature">Full access to the EasyEviction eviction management portal</div>
              </div>
              <div class="flex flex-row justify-start items-center mb-1">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature">A single place to save and manage the status of all of your clients' evictions
                </div>
              </div>
              <div *ngIf="form.get('plan').value !== subscriptionPlans.STARTER" class="flex flex-row justify-start items-center mb-1">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature">Invite an unlimited number of property manager clients to their own free portal
                </div>
              </div>
              <div *ngIf="form.get('plan').value === subscriptionPlans.STARTER"
                   class="flex flex-row justify-start items-center mb-1">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature">Add and manage an unlimited number of property manager clients</div>
              </div>
              <div *ngIf="form.get('plan').value === subscriptionPlans.STARTER"
                   class="flex flex-row justify-start items-center mb-1">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature">Auto-generate your paperwork with the click of a button</div>
              </div>
              <div *ngIf="form.get('plan').value !== subscriptionPlans.STARTER"
                   class="flex flex-row justify-start items-center mb-1">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature">We'll help you set up your paperwork to auto-generate</div>
              </div>
              <div *ngIf="form.get('plan').value === subscriptionPlans.STARTER"
                   class="flex flex-row justify-start items-center mb-1">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature">Generate and send invoices to your clients</div>
              </div>
              <div *ngIf="form.get('plan').value !== subscriptionPlans.STARTER"
                   class="flex flex-row justify-start items-center">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature">Send invoices and accept online payments from clients all in one place</div>
              </div>
            </div>
          </div>
          <div *ngIf="form.get('type').value === accountTypes.CLIENT">
            <p class="free-trial">
              <strong *ngIf="form.get('plan').value === subscriptionPlans.CLIENT_FREE">Your free account
                includes:</strong>
              <strong *ngIf="form.get('plan').value === subscriptionPlans.CLIENT_PRO">Your 14 day free trial of the PRO
                plan includes:</strong>
            </p>
            <div class="flex flex-col justify-start items-start">
              <div class="flex flex-row justify-start items-center mb-1"
                   *ngIf="form.get('plan').value === subscriptionPlans.CLIENT_PRO">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature"><b>No credit card is required during the trial</b></div>
              </div>
              <div class="flex flex-row justify-start items-center mb-1"
                   *ngIf="form.get('plan').value === subscriptionPlans.CLIENT_PRO">
                <div  class="mr-0.5 smiley">🎉</div>
                <div class="feature">
                  The ability to track attorney- and self-managed evictions for free with productivity-minded features
                  including form
                  generation, customizable workflows, and more!
                </div>
              </div>
              <div class="flex flex-row justify-start items-center mb-1"
                   *ngIf="form.get('plan').value === subscriptionPlans.CLIENT_FREE">
                <div class="smiley mr-0.5">🎉</div>
                <div class="feature">Access to your own exclusive Property Manager Portal where you can submit and track
                  all of your evictions
                </div>
              </div>
              <div class="flex flex-row justify-start items-center mb-1">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature">Bulk import your evictions from a spreadsheet to save on time</div>
              </div>
              <div *ngIf="form.get('plan').value === subscriptionPlans.CLIENT_PRO"
                   class="flex flex-row justify-start items-center mb-1">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature">Auto-generate your paperwork with the click of a button</div>
              </div>
              <div class="flex flex-row justify-start items-center mb-1"
                   *ngIf="form.get('plan').value === subscriptionPlans.CLIENT_FREE">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature">Analyze each step of the process and stay in control</div>
              </div>
              <div class="flex flex-row justify-start items-center mb-1">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature">Properties all over the country? Invite and message every attorney and keep track
                  of
                  your evictions all in one place
                </div>
              </div>
              <div *ngIf="form.get('plan').value === subscriptionPlans.CLIENT_FREE"
                   class="flex flex-row justify-start items-center mb-1">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature">View your invoices from all of your attorneys in one place</div>
              </div>
              <div *ngIf="form.get('plan').value === subscriptionPlans.CLIENT_PRO"
                   class="flex flex-row justify-start items-center mb-1">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature">View your invoices and pay every attorneys online in one place</div>
              </div>
              <div *ngIf="form.get('plan').value === subscriptionPlans.CLIENT_PRO"
                   class="flex flex-row justify-start items-center">
                <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
                <div class="feature">Generate eviction and invoice reports to keep track of the health of your
                  practice
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col justify-start items-start">
            <div class="flex flex-row justify-start items-center mb-1">
              <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
              <div class="feature">Have questions? Need a demo? Send us a message from the portal!</div>
            </div>
            <div class="flex flex-row justify-start items-center"
                 *ngIf="form.get('plan').value !== subscriptionPlans.CLIENT_FREE">
              <mat-icon class="mr-0.5" fontSet="fa" fontIcon="fa-check"></mat-icon>
              <div class="feature">Cancel your subscription anytime. For any reason.</div>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
