import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {BillableItem, CreditNote, Invoice} from '@ee/common/models';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RefreshMetrics} from '../actions/auth.actions';
import {Loading} from '../actions/ui.actions';
import {ShowAlertWithLink, ShowManualClosableAlert} from '../actions/alert.actions';
import {CreditNoteService, DocumentService, InvoiceService} from '@ee/common/services';
import {
  ApplyInvoiceCredit,
  CreditNotesLoaded,
  DeleteInvoice, DeleteInvoiceCredit,
  DeleteInvoicePayment,
  DownloadInvoices, InvoiceDeleted,
  InvoiceSaved,
  InvoicesPaymentRecorded, LoadCreditNotes, LoadInvoice, LoadInvoiceBillableItems, LoadInvoiceDashboardWidgets, LoadInvoices,
  RecordInvoicePayment,
  RecordInvoicePayments, RemoveOnlinePaymentOption, SetCurrentInvoice, SetInvoiceBillableItems, SetInvoiceDashboardWidgets, SetInvoices
} from '../actions/invoice.actions';

@Injectable()
export class InvoiceEffects {
  loadInvoices$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadInvoices),
      switchMap(() => this.http.get<Invoice[]>(`${environment.api_prefix}api/invoices`)),
      switchMap((invoices: Invoice[]) => [SetInvoices(invoices), new Loading(false)])
    )
  );

  loadCurrentInvoice$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadInvoice),
      switchMap(({payload}) => this.http.get<Invoice>(`${environment.api_prefix}api/invoices/${payload}`)),
      switchMap((invoice: Invoice) => [SetCurrentInvoice(invoice)])
    )
  );

  loadCreditNotes$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCreditNotes),
      switchMap(({invoiceId}) => this.creditNoteService.findByInvoiceId(invoiceId)),
      switchMap((creditNotes: CreditNote[]) => [CreditNotesLoaded(creditNotes)])
    )
  );

  loadDashboardWidgets$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadInvoiceDashboardWidgets),
      switchMap(() =>
        this.invoiceService.getInvoiceDashboard()
          .pipe(map((widgets: {[key: string]: any}) => SetInvoiceDashboardWidgets(widgets)))
      )
    )
  );

  loadBillableItems$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadInvoiceBillableItems),
      switchMap(({ clientId, includeClosedCases }) =>
        this.http
          .get<Invoice[]>(`${environment.api_prefix}api/organizations/${clientId}/uninvoiced-billable-items`, {
            params: { includeClosedCases: includeClosedCases.toString() }
          })
          .pipe(map((billableItems: BillableItem[]) => SetInvoiceBillableItems(billableItems)))
      )
    )
  );

  deleteInvoice$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteInvoice),
      switchMap(({ payload }) =>
        this.http.delete(`${environment.api_prefix}api/invoices/${payload}`).pipe(
          switchMap(() => {
            this.snackBar.open('Invoice deleted.', 'Dismiss', { duration: 3000 });
            this.router.navigate(['/invoices']); // redirect
            return [InvoiceDeleted(payload), RefreshMetrics()];
          })
        )
      )
    )
  );

  removeOnlinePaymentOption: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveOnlinePaymentOption),
      switchMap(({ payload }) =>
        this.http
          .put<Invoice>(`${environment.api_prefix}api/invoices/${payload}/remove-online-payment-option`, {})
          .pipe(switchMap((invoice: Invoice) => [InvoiceSaved(invoice), RefreshMetrics()]))
      )
    )
  );

  recordInvoicePayment$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordInvoicePayment),
      switchMap(action =>
        this.http.post<Invoice>(`${environment.api_prefix}api/invoices/${action.id}/payment`, action.invoicePayment).pipe(
          switchMap((invoice: Invoice) => {
            this.snackBar.open('Payment recorded.', 'Dismiss', { duration: 3000 });
            return [InvoiceSaved(invoice), RefreshMetrics()];
          })
        )
      )
    )
  );

  recordInvoicePayments$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordInvoicePayments),
      switchMap(action =>
        this.http.post<Invoice[]>(`${environment.api_prefix}api/invoices/payments`, action.invoicePayments).pipe(
          switchMap((invoices: Invoice[]) => {
            this.snackBar.open('Invoice payments recorded successfully.', 'Dismiss', { duration: 3000 });
            return [InvoicesPaymentRecorded(invoices), RefreshMetrics()];
          })
        )
      )
    )
  );

  deleteInvoicePayment$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteInvoicePayment),
      switchMap(action =>
        this.http
          .delete<Invoice>(`${environment.api_prefix}api/invoices/${action.id}/payment/${action.paymentId}`, {
            body: { reason: action.reason }
          })
          .pipe(
            switchMap((invoice: Invoice) => {
              this.snackBar.open('Payment reversal recorded.', 'Dismiss', { duration: 3000 });
              return [InvoiceSaved(invoice), RefreshMetrics()];
            })
          )
      )
    )
  );

  applyInvoiceCredit$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplyInvoiceCredit),
      switchMap(action =>
        this.http.post<Invoice>(`${environment.api_prefix}api/invoices/${action.id}/credit`, action.invoiceCredit).pipe(
          switchMap((invoice: Invoice) => {
            this.snackBar.open('Credit applied.', 'Dismiss', { duration: 3000 });
            return [InvoiceSaved(invoice), RefreshMetrics()];
          })
        )
      )
    )
  );

  deleteInvoiceCredit$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteInvoiceCredit),
      switchMap(action =>
        this.http
          .delete<Invoice>(`${environment.api_prefix}api/invoices/${action.id}/credit/${action.creditId}`, {
            body: { reason: action.reason }
          })
          .pipe(
            switchMap((invoice: Invoice) => {
              this.snackBar.open('Credit reversal recorded.', 'Dismiss', { duration: 3000 });
              return [InvoiceSaved(invoice), RefreshMetrics()];
            })
          )
      )
    )
  );

  downloadCaseFiles$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DownloadInvoices),
      switchMap(payload =>
        this.documentService
          .downloadInvoices(payload.invoicesIds)
          .pipe(map((result: { downloadUrl: string }) => new ShowAlertWithLink('Your files are ready.', 'Download files.', result.downloadUrl)))
      ),
      catchError(() => [new ShowManualClosableAlert('An error occurred downloading invoices pdf\'s for download. Please try again.')])
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private creditNoteService: CreditNoteService,
    private documentService: DocumentService,
    private invoiceService: InvoiceService
  ) {}
}
