import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Observable, withLatestFrom} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as evictionActions from '../actions/eviction.actions';
import {REFRESH_CASE_TENANTS, RefreshCaseTenants, UpdateCaseScraCheckStatus, WatchSettingsUpdated} from '../actions/eviction.actions';
import * as fromEviction from '../../core/reducers/eviction.reducer';
import * as clientActions from '../actions/organization.actions';
import * as messageActions from '../actions/message.actions';
import * as noteActions from '../actions/note.actions';
import * as attachmentActions from '../actions/attachment.actions';
import {RefreshEvictionAttachments} from '../actions/attachment.actions';
import * as authActions from '../actions/auth.actions';
import {RefreshMetrics} from '../actions/auth.actions';
import * as fromApp from '../reducers/app.reducer';
import {
  Attachment,
  BillableItem,
  CaseWatcherSettings,
  ClientAnswers,
  Eviction,
  KeyValue,
  Organization,
  ScheduledEmailSettings,
  StepCompletionModel,
  Tag,
  Tenant,
  User
} from '@ee/common/models';
import {Store} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ShowAlertWithLink, ShowAutoClosableAlert, ShowManualClosableAlert} from '../actions/alert.actions';
import {DocumentService, EvictionService, FormService} from '@ee/common/services';
import {Router} from '@angular/router';
import {SetCaseTasks} from '../actions/case-tasks.actions';
import {ScraScanStatus} from '@ee/common/enums';
import {InvoiceBillableItemDeleted, InvoiceBillableItemUpdated} from '../actions/invoice.actions';

@Injectable()
export class EvictionEffects {

  loadCurrentEviction$ = createEffect(() => this.actions$.pipe(
    ofType(evictionActions.LOAD_EVICTION),
    map((action: evictionActions.LoadEviction) => action),
    concatLatestFrom(() => this.store.select(fromApp.getLoggedInUser)),
    switchMap(([action, user]) =>
      this.evictionService.getEvictionByCaseId(action.payload, action.queryParams).pipe(
        switchMap((result: Eviction) => [
          new evictionActions.SetCurrentEviction(result),
          new clientActions.LoadEvictionClient(result.client_id),
          new messageActions.SetEvictionMessages(result.messages),
          new noteActions.SetEvictionNotes(result.notes),
          new attachmentActions.SetEvictionAttachments(result.attachments),
          new evictionActions.SetEvictionBills(result.billable_items),
          SetCaseTasks(result.tasks),
          authActions.RefreshMetrics()
        ]),
        catchError((error: HttpErrorResponse) => {
          let message = 'Eviction not found';
          let redirectTo = '/dashboard';
          if (error.status === 404) {
            if (user.organization_count > 1) {
              message += ' on this account. Please switch accounts and try again';
              redirectTo = '/user-settings/accounts';
            }
          } else {
            message = 'Error loading eviction';
          }
          this.router.navigate([redirectTo]);
          return [new ShowAutoClosableAlert(`${message}.`)];
        })
      ))
  ));

  updateClientStatus$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(evictionActions.EVICTION_STATUS_UPDATE),
    map((action: evictionActions.UpdateEvictionStatus) => action),
    switchMap((params: any) =>
      this.http.put<Eviction>(`${environment.api_prefix}api/evictions/${params.caseId}/client-update`, params.payload)
        .pipe(switchMap((result: Eviction) => [
          new evictionActions.EvictionStatusUpdated(result),
          RefreshMetrics()
        ])
        ))
  ));


  updateCourtResult$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(evictionActions.UPDATE_COURT_RESULT),
    map((action: evictionActions.UpdateCourtResult) => action),
    switchMap((action) =>
      this.http.put(`${environment.api_prefix}api/evictions/${action.caseId}/update-court-result`,
        action.courtResult, {responseType: 'text'})
        .pipe(switchMap((result: string) => [new evictionActions.CourtResultUpdated(action.caseId, result)])
        ))
  ));


  updateEvictionDetails$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(evictionActions.UPDATE_EVICTION_DETAILS),
    map((action: evictionActions.UpdateEvictionDetails) => action.payload),
    switchMap((updatedEviction: Eviction) =>
      this.evictionService.updateEvictionDetails(updatedEviction).pipe(
        switchMap((response: Eviction) => [new evictionActions.EvictionStepCompleted(response)])))
  ));


  completeEvictionStep$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(evictionActions.COMPLETE_STEP),
    map((action: evictionActions.EvictionCompleteStep) => action.payload),
    switchMap((payload: StepCompletionModel) =>
      this.evictionService.saveStepCompletion(payload).pipe(
        switchMap((response: Eviction) => {
          const resultActions: any[] = [
            new evictionActions.EvictionStepCompleted(response),
            new evictionActions.SetEvictionBills(response.billable_items),
            new noteActions.SetEvictionNotes(response.notes),
            new attachmentActions.SetEvictionAttachments(response.attachments),
            SetCaseTasks(response.tasks)
          ];
          // poll for attachments that are not ready
          response.attachments.filter(a => !a.deleted && !a.ready).forEach(a => {
            resultActions.push(new attachmentActions.PollForAttachment(a.id));
          });
          return resultActions;
        })))
  ));


  sendClientAnswer$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(evictionActions.SEND_CLIENT_ANSWER),
    map((action: evictionActions.SendClientAnswers) => action.answers),
    switchMap((payload: ClientAnswers) =>
      this.evictionService.saveClientAnswers(payload).pipe(
        switchMap((response: Eviction) => [
          new evictionActions.EvictionStepCompleted(response),
          new messageActions.SetEvictionMessages(response.messages)
        ])))
  ));


  saveBillableItem$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(evictionActions.SAVE_BILL),
    map((action: evictionActions.SaveBill) => action.payload),
    switchMap((billableItem: BillableItem) =>
      this.http.post<BillableItem>(
        `${environment.api_prefix}api/evictions/${billableItem.case_id}/billable`, billableItem
      ).pipe(
        switchMap((savedBillableItem: BillableItem) => {
          this.snackBar.open('Billable item saved', 'Dismiss', {duration: 3000});
          return [new evictionActions.BillSaved(savedBillableItem)];
        })
      ))
  ));


  updateBillableItem$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(evictionActions.UPDATE_BILL),
    map((action: evictionActions.UpdateBill) => action.payload),
    switchMap((updatedValue: BillableItem) =>
      this.http.put<BillableItem>(
        `${environment.api_prefix}api/evictions/${updatedValue.case_id}/billable`, updatedValue
      ).pipe(
        switchMap((updatedBillableItem: BillableItem) => {
          this.snackBar.open('Billable item updated', 'Dismiss', {duration: 3000});
          return [
            new evictionActions.BillUpdated(updatedBillableItem),
            InvoiceBillableItemUpdated(updatedBillableItem)
          ];
        })
      ))
  ));


  deleteBillableItem$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(evictionActions.DELETE_BILL),
    map((action: evictionActions.DeleteBill) => action),
    switchMap(({payload, userName}) =>
      this.http.delete(`${environment.api_prefix}api/evictions/${payload.case_id}/billable/${payload.id}`)
        .pipe(
          switchMap(() => {
            this.snackBar.open('Billable item deleted', 'Dismiss', {duration: 3000});
            return [
              new evictionActions.BillDeleted(payload.id, userName),
              InvoiceBillableItemDeleted(payload.id)
            ];
          }),
          catchError(() => [new ShowAutoClosableAlert('Error deleting billable item.')])
        ))
  ));

  reopenEviction$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(evictionActions.EVICTION_REOPEN),
    map((action: evictionActions.EvictionReopen) => action.payload),
    switchMap((evictionReopen: KeyValue<string, number>) =>
      this.http.put<Eviction>(
        `${environment.api_prefix}api/evictions/${evictionReopen.key}/reopen/${evictionReopen.value}`,
        null).pipe(map((eviction) => {
        this.snackBar.open('Eviction successfully reopened.', 'Dismiss', {duration: 3000});
        return new evictionActions.EvictionReopened(eviction);
      })
      ))
  ));


  saveTags$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(evictionActions.SAVE_CASE_TAGS),
    map((action: evictionActions.SaveCaseTags) => action),
    switchMap((params: any) =>
      this.http.put<Tag[]>(
        `${environment.api_prefix}api/evictions/${params.caseId}/tags`, params.tags)
        .pipe(map((tags: Tag[]) => new evictionActions.TagsUpdated(tags))
        ))
  ));


  cancelScheduledEmail$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(evictionActions.CANCEL_SCHEDULED_EMAIL),
    map((action: evictionActions.CancelScheduledEmail) => action.payload),
    switchMap((scheduledEmailId) =>
      this.http.get<ScheduledEmailSettings[]>(
        `${environment.api_prefix}api/evictions/cancel-scheduled-email/${scheduledEmailId}`
      ).pipe(
        map((updatedStatusHistory: ScheduledEmailSettings[]) =>
          new evictionActions.ScheduledEmailCanceled(updatedStatusHistory))
      ))
  ));


  generateFillableFormDetails: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(evictionActions.GENERATE_FILLABLE_FORM_DETAILS),
    map((action: evictionActions.GenerateFillableFormDetails) => action),
    switchMap((params: any) =>
      this.http.get<Map<string, string>>(`${environment.api_prefix}api/evictions/${params.caseId}/form-fill-details`)
        .pipe(
          map((details: Map<string, string>) => this.formService.generatePreview(params.formPath, details)),
          switchMap(() => [new ShowAutoClosableAlert('Preview generated.')])
        )),
    catchError(() => [new ShowAutoClosableAlert('Error generating preview.')])
  ));

  watchEviction$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(evictionActions.WATCH_CASE),
    map((action: evictionActions.WatchCase) => action),
    switchMap((params: { caseId: string, user: User, organization: Organization, watch?: CaseWatcherSettings }) =>
      this.http.put<CaseWatcherSettings>(`${environment.api_prefix}api/evictions/${params.caseId}/watch`, params.watch ?? null)
        .pipe(
          map((result) => new WatchSettingsUpdated(params.user, params.organization, result))
        )),
    catchError(() => [new ShowAutoClosableAlert('Error updating eviction watch preferences.')])
  ));

  downloadCaseFiles$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(evictionActions.DOWNLOAD_CASE_FILES),
    map((action: evictionActions.DownloadCaseFiles) => action),
    switchMap((params: { caseIds: string[] }) =>
      this.documentService.downloadCaseFiles(params.caseIds).pipe(map((result: {
          downloadUrl: string
        }) => new ShowAlertWithLink('Your files are ready.',  'Download files.', result.downloadUrl))
      )),
    catchError(() => [new ShowManualClosableAlert('An error occurred setting up case files for download. Please try again.')])
  ));

  scraCheck$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(evictionActions.DO_SCRA_CHECK),
    map((action: evictionActions.DoScraCheck) => action),
    switchMap((params: { caseId: string, tenantId: string }) =>
      this.evictionService.initScraCheck(params.caseId, params.tenantId).pipe(
        switchMap((result: { success: boolean, tenants: Tenant[], attachments: Attachment[], caseId: string }) => {
          const loadEviction: any[] = [];
          if (result.success) {
            loadEviction.push(new RefreshEvictionAttachments(result.caseId, result.attachments));
            loadEviction.push(new RefreshCaseTenants(result.caseId, result.tenants));
          } else {
            loadEviction.push(new ShowAutoClosableAlert('The SCRA check failed.'));
            loadEviction.push(new UpdateCaseScraCheckStatus(params.tenantId, ScraScanStatus.DATA_ERROR));
          }
          return loadEviction;
        }
        ))),
    catchError(() => [new ShowManualClosableAlert('An error occurred attempting to check for this tenant\'s SCRA record. Please try again.')])
  ));


  refreshCaseTenants$: Observable<any> = createEffect(() => this.actions$?.pipe(
    ofType(REFRESH_CASE_TENANTS),
    map((action: evictionActions.RefreshCaseTenants) => action),
    withLatestFrom(this.store.select(fromEviction.getCurrentEviction)),
    switchMap(([payload, eviction]) => {
      const loadEviction: any[] = [];
      if (eviction.case_id === payload.caseId) {
        loadEviction.push(new evictionActions.UpdateCaseTenant(payload.tenants));
      }
      return loadEviction;
    })
  ));

  constructor(private actions$: Actions, private store: Store, private http: HttpClient,
              private router: Router, private snackBar: MatSnackBar, private formService: FormService,
              private evictionService: EvictionService, private documentService: DocumentService) {
  }
}
