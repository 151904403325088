import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Store} from '@ngrx/store';
import {ActivatedRouteSnapshot, NavigationEnd, NavigationError, Router} from '@angular/router';
import {GetLoggedInUser} from './core/actions/auth.actions';
import {AuthService} from '@ee/common/services';
import {firstValueFrom, fromEvent, Observable, Subscription} from 'rxjs';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {OfflineSnackbarComponent} from './shared-components/offline-snackbar.component';
import {PwaService} from './pwa.service';
import {productFruits } from 'product-fruits';

@Component({
  selector: 'ee-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;
  public subscriptions: Subscription[] = [];
  public offlineSnackbarRef: MatSnackBarRef<any>;
  title = 'EasyEviction.com';

  constructor(
    private titleService: Title,
    private store: Store,
    private router: Router,
    private snackBar: MatSnackBar,
    private pwaService: PwaService,
    private authService: AuthService
  ) {
  }

  public static isIgnoreRedirectedPath(path: string) {
    return (
      path.startsWith('/i/') ||
      path.startsWith('/complete-client-registration') ||
      path.startsWith('/activate') ||
      path.startsWith('/register') ||
      path.startsWith('/authorize')
    );
  }

  private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
    let title: string = routeSnapshot.data && routeSnapshot.data.pageTitle ? 'EasyEviction | ' + routeSnapshot.data.pageTitle : 'EasyEviction';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    } else if (routeSnapshot?.params?.id) {
      title = title + ' | ' + routeSnapshot.params.id;
    }
    return title;
  }

  async ngOnInit() {
    // kick off subscription check on startup
    this.pwaService.start();

    let path = window.location.pathname;
    if (path === '/' || path === '') {
      path = '/dashboard';
    }

    // check the auth status -- unless trying to pull up a landing or auth page
    if (!AppComponent.isIgnoreRedirectedPath(path)) {
      const isLoggedIn = await firstValueFrom(this.authService.isLoggedIn());
      if (isLoggedIn) {
        this.store.dispatch(GetLoggedInUser(true, path));
      } else {
        this.authService.login(path);
      }
    }

    this.subscriptions.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.titleService.setTitle(this.getPageTitle(this.router.routerState.snapshot.root));
          productFruits.safeExec(($productFruits) => {
            $productFruits.push(['pageChanged']);
          });
        }
        if (event instanceof NavigationError && event.error.status === 404) {
          this.router.navigate(['/404']);
        }
      })
    );

    // set up online/offline listeners
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(
      this.onlineEvent.subscribe(() => {
        if (this.offlineSnackbarRef) {
          this.offlineSnackbarRef.dismiss();
        }
      })
    );
    this.subscriptions.push(
      this.offlineEvent.subscribe(() => {
        this.offlineSnackbarRef = this.snackBar.openFromComponent(OfflineSnackbarComponent, {
          data: {
            message: 'Looks like you\'re offline. EasyEviction may not work properly.',
            dismissable: false
          }
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
