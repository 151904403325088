import { Route } from '@angular/router';
import { AuthorizeComponent } from './authorize.component';

export const authorizeRoute: Route = {
  path: 'authorize',
  component: AuthorizeComponent,
  data: {
    pageTitle: 'Login'
  }
};
