import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as organizationActions from '../actions/organization.actions';
import {Organization} from '@ee/common/models';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import * as authActions from '../actions/auth.actions';
import {LoggedInOrgUpdated} from '../actions/auth.actions';
import {ShowAutoClosableAlert} from '../actions/alert.actions';
import {Router} from '@angular/router';
import {ClientService, UserService} from '@ee/common/services';
import * as fromApp from '../reducers/app.reducer';
import {Store} from '@ngrx/store';
import {AccountType} from '@ee/common/enums';

@Injectable()
export class OrganizationEffects {


  loadCurrentEvictionOrganization$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(organizationActions.LOAD_EVICTION_CLIENT),
    map((action: organizationActions.LoadEvictionClient) => action.payload),
    concatLatestFrom(() => this.store.select(fromApp.getLoggedInOrg)),
    switchMap(([payload, org]) => {
      if (org.type === AccountType.CLIENT || org.type === AccountType.PROPERTY_MANAGER) {
        return of(new organizationActions.SetCurrentEvictionClient(org));
      }
      return this.clientService.getClient(payload).pipe(
        map((client: Organization) => new organizationActions.SetCurrentEvictionClient(client))
      );
    })
  ));


  updateDefaultWorkflow$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(organizationActions.UPDATE_DEFAULT_WORKFLOW),
    map((action: organizationActions.UpdateDefaultWorkflow) => action.payload),
    switchMap(payload => this.http.put<boolean>(`${environment.api_prefix}api/organizations/workflows/default/${payload}`, null).pipe(
      map(() => new organizationActions.UpdateDefaultWorkflowComplete(payload))
    ))
  ));


  addClient$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(organizationActions.ADD_NEW_CLIENT),
    map((action: organizationActions.AddNewClient) => action),
    switchMap((action) => this.clientService.addNewClient(action.payload).pipe(
      switchMap((createdClient: Organization) => {
        if (action.redirectOnCreate) {
          this.router.navigate([`/clients/${createdClient?.identifier}`]); // redirect
        }
        return [new ShowAutoClosableAlert('Client added.')];
      }),
      catchError(() => [authActions.AuthError('Error creating client. Please try again.')])
    ))
  ));


  addAttorney$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(organizationActions.ADD_ATTORNEY),
    map((action: organizationActions.AddAttorney) => action.payload),
    switchMap(payload => this.http.post(`${environment.api_prefix}api/organizations/attorneys`, payload).pipe(
      switchMap((attorney: Organization) => [
        new organizationActions.AttorneyAdded(attorney)
      ]),
      catchError(() => [authActions.AuthError('Error creating attorney. Please try again.')])
    ))
  ));


  clientAddAttorneyRequest$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(organizationActions.CLIENT_ADD_ATTORNEY_REQUEST),
    map((action: organizationActions.ClientAddAttorneyRequest) => action.attorneyKey),
    switchMap(attorneyKey => this.http.post(`${environment.api_prefix}api/organizations/attorney-request/${attorneyKey}`, null).pipe(
      switchMap((client: Organization) => [
        LoggedInOrgUpdated(client)
      ]),
      catchError(() => [authActions.AuthError('Error encountered. Please try again.')])
    ))
  ));


  confirmAttorneyRequest$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(organizationActions.CONFIRM_ATTORNEY_REQUEST),
    map((action: organizationActions.ConfirmAttorneyRequest) => action),
    switchMap(payload => this.http.post(`${environment.api_prefix}api/organizations/confirm-attorney-request` +
        `/${payload.clientId}`, payload.decision).pipe(
      switchMap(() => {
        this.router.navigate(['/clients']); // redirect to client list

        return [
        ];
      }),
      catchError(() => [authActions.AuthError('Error encountered. Please try again.')])
    ))
  ));


  inviteClient$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(organizationActions.INVITE_CLIENT),
    map((action: organizationActions.InviteClient) => action),
    switchMap((action) =>
      this.http.post(`${environment.api_prefix}api/organizations/invite-client/${action.clientId}`,
        action.emailDetails).pipe(
        switchMap(() => [
          new ShowAutoClosableAlert('Invitation email successfully sent.')
        ]),
        catchError(() => [authActions.AuthError('Error encountered. Please try again.')])
      ))
  ));

  constructor(private actions$: Actions, private http: HttpClient, private clientService: ClientService,
              private userService: UserService, private router: Router, private store: Store) {
  }
}
