<div class="ee-user-menu" *ngIf="{currentOrg: currentOrg$ | async} as userOrg">
  <button type="button" #userDetailsMenuTrigger="matMenuTrigger" class="ee-user-menu-button" mat-button
     [matMenuTriggerFor]="userDetailsMenu">
    <span class="ee-user-menu-button__username" [ngStyle]="{'--user-selector-color': currentUser?.color}"
          [attr.data-letters]="(currentUser ? currentUser?.display_name : '') | dataLetters">
      <span class="hidden md:inline ml-4">{{userOrg.currentOrg ? userOrg.currentOrg.company_name : ''}}</span>
    </span>
  </button>
  <mat-menu #userDetailsMenu="matMenu" class="portal-menu-no-padding">
    <div mat-menu-item disabled class="ee-user-menu-header flex flex-row justify-center items-center"
         style="height:100px">
      <span class="ee-user-menu-header__avatar" [ngStyle]="{'--user-selector-color': currentUser?.color}"
            [attr.data-letters]="(currentUser ? currentUser?.display_name : '') | dataLetters"></span>
      <div class="ee-user-menu-header__details">
        <h2>{{currentUser ? currentUser?.first_name + ' ' + currentUser?.last_name : ''}}</h2>
        <h3 class="font-bold leading-4">{{userOrg.currentOrg?.company_name}}</h3>
        <div class="version" *ngIf="currentVersion !== 'VERSION'">
          {{currentVersion}}
        </div>
      </div>
    </div>

    <a mat-menu-item routerLink="/user-settings">
      <mat-icon fontSet="fa" fontIcon="fa-user-circle" matListIcon></mat-icon>
      <span class="label">User Preferences</span>
    </a>
    <button type="button" *ngIf="currentUser?.is_admin" mat-menu-item
            [matMenuTriggerFor]="adminMenu">
      <mat-icon fontSet="fa" fontIcon="fa-cog" matListIcon></mat-icon>
      <span class="label">Admin Settings</span>
    </button>
    <a mat-menu-item (click)="startTour(userOrg.currentOrg)" target="_blank">
      <mat-icon fontSet="fa" fontIcon="fa-play-circle" matListIcon></mat-icon>
      <span class="label">Start Tour</span>
    </a>
    <a id="product-roadmap" mat-menu-item (click)="openRoadmap()">
      <mat-icon fontSet="fa" fontIcon="fa-rocket-launch" matListIcon></mat-icon>
      <span class="label">Product Roadmap</span>
    </a>
    <a mat-menu-item href="https://help.easyeviction.com/" target="_blank">
      <mat-icon fontSet="fa" fontIcon="fa-question-circle" matListIcon></mat-icon>
      <span class="label">Help Center</span>
    </a>
    <a mat-menu-item href="https://easyeviction.com/privacy/" target="_blank">
      <mat-icon fontSet="fa" fontIcon="fa-parking-circle" matListIcon></mat-icon>
      <span class="label">Privacy Policy</span>
    </a>
    <a mat-menu-item href="https://easyeviction.com/terms/" target="_blank">
      <mat-icon fontSet="fa" fontIcon="fa-info-circle" matListIcon></mat-icon>
      <span class="label">Terms of Service</span>
    </a>
    <mat-divider></mat-divider>
    <a class="sign-out" mat-menu-item (click)="signOut()">
      <mat-icon fontSet="fa" fontIcon="fa-sign-out-alt" matListIcon></mat-icon>
      <span class="label">Sign Out</span>
    </a>
  </mat-menu>
  <mat-menu #companySwitcher="matMenu" class="portal-menu-no-padding">
    <button type="button" disabled mat-menu-item *ngIf="currentUser?.is_admin">
      <mat-icon fontSet="fa" fontIcon="fa-building" matListIcon></mat-icon>
      <span class="label">{{userOrg.currentOrg?.company_name}}</span>
    </button>
  </mat-menu>
  <mat-menu #adminMenu="matMenu" class="portal-menu-no-padding">
    <a mat-menu-item routerLink="/admin/company-settings" *ngIf="currentUser?.is_admin">
      <mat-icon fontSet="fa" fontIcon="fa-building" matListIcon></mat-icon>
      <span class="label">Company Settings</span>
    </a>
    <a mat-menu-item routerLink="/admin/billing" *hasPermission="permissions.ADMIN_BILLING_VIEW">
      <mat-icon fontSet="fa" fontIcon="fa-credit-card" matListIcon></mat-icon>
      <span class="label">Subscription</span>
    </a>
    <a class="pro" mat-menu-item routerLink="/admin/document-templates"
       *hasPermission="permissions.ADMIN_DOCUMENT_TEMPLATES_VIEW">
      <mat-icon fontSet="fa" fontIcon="fa-file-alt" matListIcon></mat-icon>
      <span class="label">
          Document Templates
        </span>
    </a>
    <a class="pro" mat-menu-item routerLink="/admin/plugins"
       *hasPermission="permissions.ADMIN_PLUGINS_VIEW"
       matBadge="Pro" matBadgeColor="accent" matBadgePosition="after"
       [matBadgeHidden]="(currentPlan$ | async) !== plans.STARTER && (currentPlan$ | async) !== plans.CLIENT_FREE">
      <mat-icon fontSet="fa" fontIcon="fa-landmark" matListIcon></mat-icon>
      <span class="label">
          Plugins
        </span>
    </a>
    <a class="pro" mat-menu-item routerLink="/admin/users"
       *hasPermission="permissions.ADMIN_USERS_VIEW">
      <mat-icon fontSet="fa" fontIcon="fa-users" matListIcon></mat-icon>
      <span class="label">Users</span>
    </a>
    <a class="pro" mat-menu-item routerLink="/admin/my-workflows"
       *hasPermission="permissions.ADMIN_WORKFLOWS_VIEW"
       matBadge="Pro" matBadgeColor="accent" matBadgePosition="after"
       [matBadgeHidden]="(currentPlan$ | async) !== plans.STARTER && (currentPlan$ | async) !== plans.CLIENT_FREE">
      <mat-icon fontSet="fa" fontIcon="fa-location-arrow" matListIcon></mat-icon>
      <span class="label">Workflows</span>
    </a>
  </mat-menu>
</div>
