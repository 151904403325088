<h2 mat-dialog-title>Review Terms</h2>

<form mat-dialog-content class="flex flex-col" [formGroup]="form">
  <p>Please review and agree to our Terms of Service and Privacy Policy.</p>
  <div>
    <mat-checkbox formControlName="tos_confirm">
      <div class="terms-condition">I agree to the EasyEviction
        <a href="https://easyeviction.com/terms/" target="_blank">Terms of Services</a>
      </div>
    </mat-checkbox>
    <mat-checkbox formControlName="policy_confirm">
      <div class="terms-condition">I agree to the EasyEviction
        <a href="https://easyeviction.com/privacy/" target="_blank">Privacy Policy</a>
      </div>
    </mat-checkbox>
  </div>
</form>

<div mat-dialog-actions class="ee-modal-actions">
  <button mat-button (click)="confirm()" cdkFocusInitial color="accent" [disabled]="form.invalid">
    CONTINUE
  </button>
</div>
