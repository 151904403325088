import {inject, Injectable, isDevMode, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Route, RouterStateSnapshot} from '@angular/router';
import {AuthService, StateStorageService} from '@ee/common/services';
import {firstValueFrom, Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers/app.reducer';
import {SessionStorageService} from 'ngx-webstorage';
import {AUTHORITIES} from '@ee/common/constants';
import {AccountType, Permission} from '@ee/common/enums';

@Injectable({providedIn: 'root'})
export class UserRouteAccessService implements OnDestroy {
  private subs: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private stateStorageService: StateStorageService,
    private store: Store,
    private sessionStorage: SessionStorageService
  ) {
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    const authorities = route.data.authorities;
    const accountTypes = route.data.accountTypes;
    // We need to call the checkLogin / and so the accountService.identity() function, to ensure,
    // that the client has a principal too, if they already logged in by the server.
    // This could happen on a page refresh.
    return this.checkAuthorities(authorities, accountTypes);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authorities = route.data.authorities;
    const accountTypes = route.data.accountTypes;
    const permissions = route.data.permissions;
    const permissionOperator: 'AND' | 'OR' = route.data.permissionOperator ?? 'AND';
    const isAdmin = typeof route.data.isAdmin === 'boolean' ? route.data.isAdmin : false;
    // We need to call the checkLogin / and so the accountService.identity() function, to ensure,
    // that the client has a principal too, if they already logged in by the server.
    // This could happen on a page refresh.
    return this.checkLogin(authorities, accountTypes, permissions, permissionOperator, isAdmin, state.url);
  }

  checkAuthorities(authorities: string[], accountTypes: AccountType[]): boolean {
    return (!authorities || authorities.length === 0) && (!accountTypes || accountTypes.length === 0);
  }

  async checkLogin(authorities: string[], accountTypes: AccountType[], permissions: Permission[], permissionOperator: 'AND' | 'OR', isAdmin: boolean, url: string = null) {
    const user = await firstValueFrom(this.store.select(fromRoot.getLoggedInUser));
    const org = await firstValueFrom(this.store.select(fromRoot.getLoggedInOrg));
    let userAuthorities: string[] = this.sessionStorage.retrieve(AUTHORITIES);

    if (!userAuthorities && user) {
      userAuthorities = user.authorities;
    }

    let authorityCheck = false;
    if (authorities && authorities.length) {
      if (userAuthorities) {
        const hasAnyAuthority = this.authService.arrayHasAnyAuthority(userAuthorities, authorities);
        if (hasAnyAuthority) {
          if (!accountTypes || accountTypes.length === 0 || accountTypes.includes(org.type)) {
            authorityCheck = true;
          } else if (isDevMode()) {
            console.error('User does not have the required account type: ', accountTypes);
          }
        } else if (isDevMode()) {
          console.error('User does not have the required authorities: ', authorities);
        }
      }
    } else {
      authorityCheck = true;
    }

    let permissionCheck = false;
    if (permissions && permissions.length) {
      if (user.permissions?.length) {
        if (permissionOperator === 'AND') {
          permissionCheck = permissions.every(p => user.permissions.includes(p));
        } else {
          permissionCheck = permissions.some(p => user.permissions.includes(p));
        }
      }
    } else {
      permissionCheck = true;
    }

    const adminCheck = !isAdmin || user.is_admin;

    if (authorityCheck && permissionCheck && adminCheck) {
      return true;
    }

    if (url) {
      this.stateStorageService.storeUrl(url);
    }

    return false;
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}

export const canActivateUserRoute: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(UserRouteAccessService).canActivate(route, state);
  };
