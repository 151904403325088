import {
  AppSettingsLoaded,
  Authenticated,
  AuthError,
  DashboardWidgetsUpdated, FeatureFlagUpdated,
  GetLoggedInUser,
  LaunchAttorneyGettingStarted,
  LoadAppSettings,
  LoggedInOrgUpdated,
  Logout,
  NotAuthenticated,
  RefreshLoggedInOrg,
  RefreshMetrics, RefreshTaskCount, SetAttorneyClientSettings,
  SetLoggedInOrg, UpdateFeatureFlag
} from '../actions/auth.actions';
import * as notificationActions from '../actions/notification.actions';
import {DashboardWidgets, Organization, User} from '@ee/common/models';
import {Observable, withLatestFrom} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AuthService, FeatureFlagService, UserService} from '@ee/common/services';
import {ShowAutoClosableAlert} from '../actions/alert.actions';
import {SessionStorageService} from 'ngx-webstorage';
import { AUTHORITIES, LAST_UNAUTH_ATTEMPT, LAST_UNAUTH_TS, SUBSCRIPTION, SUBSCRIPTION_START } from '@ee/common/constants';
import { productFruits } from 'product-fruits';
import {Store} from '@ngrx/store';
import * as fromApp from '../reducers/app.reducer';

@Injectable()
export class AuthEffects {

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(Logout),
    map(() => {
      if ((window as any).Tawk_API) {
        (window as any).Tawk_API.logout();
      }
      // log out from auth0
      this.authService.logout();
    })
  )
  , {dispatch: false});


  notAuthenticated$ = createEffect(() => this.actions$?.pipe(
    ofType(NotAuthenticated),
    map(() => {
      const currentTs = Date.now().valueOf();
      const lastUnauthTs = this.sessionStorage.retrieve(LAST_UNAUTH_TS) as number ?? 0;
      const lastUnauthAttempt = this.sessionStorage.retrieve(LAST_UNAUTH_ATTEMPT) as number ?? 0;
      if ((lastUnauthAttempt < 3) && (!lastUnauthTs || ((currentTs - lastUnauthTs) < 3000))) { // 3 seconds
        this.sessionStorage.store(LAST_UNAUTH_TS, currentTs);
        this.sessionStorage.store(LAST_UNAUTH_ATTEMPT, lastUnauthAttempt + 1);
        this.authService.login();
      } else {
        this.sessionStorage.clear(LAST_UNAUTH_TS);
        this.sessionStorage.clear(LAST_UNAUTH_ATTEMPT);
        if (lastUnauthAttempt > 2) {
          this.router.navigate(['/404']);
        }
      }
    })
  ), {dispatch: false});


  getLoggedInUser$: Observable<any> = createEffect(() => this.actions$?.pipe(
    ofType(GetLoggedInUser),
    switchMap(({forceRefresh, redirectTo}) =>
    // get currently logged in user
      this.userService.getLoggedInUser(forceRefresh).pipe(
        mergeMap((user: User) => {
          // save subscription state
          this.sessionStorage.clear(LAST_UNAUTH_TS);
          this.sessionStorage.clear(LAST_UNAUTH_ATTEMPT);
          this.sessionStorage.store(AUTHORITIES, user.authorities ?? null);
          this.sessionStorage.store(SUBSCRIPTION,
            user.organization?.selected_plan && user.organization?.subscription_active ?
              user.organization?.selected_plan : null);
          this.sessionStorage.store(SUBSCRIPTION_START, user.organization?.created_date);

          // redirect if specified
          if (redirectTo) {
            this.router.navigate([redirectTo]);
          } else {
            this.router.navigate(['/dashboard']);
          }
          return [Authenticated(user)];
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 403) {
            if (err.error?.message === 'NO_ACTIVE_ORG_FAILURE'){
              this.router.navigate(['/no-active-accounts']);
            } else {
              this.router.navigate(['/403']);
            }
            return [];
          }
          return [NotAuthenticated()];
        })
      )
    )
  ));

  authenticated$: Observable<any> = createEffect(() => this.actions$?.pipe(
    ofType(Authenticated),
    switchMap(({user, redirectToDashboard}) => {
      // send login data to intercom
      const metrics = this.generateMetrics(user.organization?.dashboard_widgets as DashboardWidgets);

      if ((window as any).newrelic) {
        (window as any).newrelic.setUserId(user.id);
      }

      try {
        // @ts-ignore
        (window as any).Tawk_API.visitor = {
          hash: user.tawk_to_user_hash,
          userId: user.id,
          name: user.display_name,
          email: user.email
        };
        // @ts-ignore
        (window as any).Tawk_API.onLoad = () => {
          (window as any).Tawk_API.login({
            hash: user.tawk_to_user_hash,    // required
            userId: user.id,
            name: user.display_name,
            email: user.email,
            type: user.organization?.type,
            appVersion: environment.version
          }, function(error) {
            console.log(error);
          });
        };
      } catch (e) {
        console.error(e);
      }

      productFruits.init(
        environment.fruits_workspace_id,
        'en',
        {
          username: user.email,
          email: user.email,
          firstname: user.first_name,
          lastname: user.last_name,
          signUpAt: user.created_date,
          role: user.organization?.type,
          hmac: {
            hash: user.fruit_user_hash
          },
          props: {
            'IsAdmin': user.is_admin,
            current_role: user.role,
            screen_resolution: `${window.screen.width}x${window.screen.height}`,
            browser_resolution: `${window.innerWidth}x${window.innerHeight}`,
            phone: user.phone_number,
            app_version: environment.version,
            app_version_number: Number((environment.version ?? '').replace(/\D/g, '')),
            company_id: user.organization?.id,
            company_name: user.organization?.company_name,
            plan: user.organization?.selected_plan,
            Attorney: user.organization?.attorney?.company_name,
            'PropertyManager': user.organization?.property_manager?.company_name,
            'AccountType': user.organization?.type,
            'TrialEndDate': user.organization?.subscription_trial_end_date,
            ...metrics
          }
        }
      );

      if (redirectToDashboard) {
        setTimeout(() => {
          this.router.navigate(['/dashboard']);
        }, 1000);
      }

      const nextActions: any[] = [
        SetLoggedInOrg(user.organization),
        SetAttorneyClientSettings(user.organization.attorney_client_settings),
        DashboardWidgetsUpdated(user.organization.dashboard_widgets),
        new notificationActions.GetTotalUnreadNotificationCount(),
        LoadAppSettings()
      ];

      // if (user.organization?.type === AccountType.ATTORNEY) {
      //   nextActions.push(LaunchAttorneyGettingStarted());
      // }

      return nextActions;
    })
  ));


  refreshMetrics$: Observable<any> = createEffect(() => this.actions$?.pipe(
    ofType(RefreshMetrics),
    switchMap(() => this.http.get<DashboardWidgets>(`${environment.api_prefix}api/dashboard/widget-count`).pipe(
      mergeMap((widgets: DashboardWidgets) => {
        const metrics = this.generateMetrics(widgets);
        // @ts-ignore
        productFruits.safeExec(() => {
          (window as any).productFruits.identifyUser({
            props: {
              ...metrics
            }
          });
        });

        return [DashboardWidgetsUpdated(widgets)];
      }),
      catchError(() => [
        new ShowAutoClosableAlert('Error loading dashboard widgets.')
      ])
    ))
  ));

  refreshTaskCount$: Observable<any> = createEffect(() => this.actions$?.pipe(
    ofType(RefreshTaskCount),
    withLatestFrom(this.store.select(fromApp.getDashboardWidgets)),
    switchMap(([, widgets]) =>
      this.http.get<number>(`${environment.api_prefix}api/tasks/today/count`).pipe(
        map((taskCount: number) => DashboardWidgetsUpdated({...widgets, task_count: taskCount})),
      ))
  ));

  loadAttorneyGettingStarted$ = createEffect(() => this.actions$?.pipe(
    ofType(LaunchAttorneyGettingStarted),
    switchMap(() => {
      // @ts-ignore
      if (window.userGuiding) {
        (window as any).userGuiding.launchChecklist(18128);
      }
      return [];
    })
  ), {dispatch: false});

  handleAuthError$ = createEffect(() => this.actions$?.pipe(
    ofType(AuthError),
    switchMap(({message}) => {
      const m = message ?? 'Invalid username or password provided. Please try again.';
      return [
        new ShowAutoClosableAlert(m)
      ];
    })
  ));

  loadAppSettings$ = createEffect(() => this.actions$?.pipe(
    ofType(LoadAppSettings),
    switchMap(() => this.http.get<any>(`${environment.api_prefix}api/settings`).pipe(
      map((appSettings) => AppSettingsLoaded(appSettings)),
      catchError(() => [new ShowAutoClosableAlert('Error loading application settings.')])
    ))
  ));


  refreshLoggedInOrganization$ = createEffect(() => this.actions$?.pipe(
    ofType(RefreshLoggedInOrg),
    switchMap(() => this.http.get<Organization>(`${environment.api_prefix}api/organizations/current?force-refresh=true`).pipe(
      map((org: Organization) => LoggedInOrgUpdated(org))
    ))
  ));

  updateFeatureFlag$ = createEffect(() => this.actions$?.pipe(
    ofType(UpdateFeatureFlag),
    switchMap(({feature, enabled}) => this.featureFlagService.updateAttorneyFeatureFlag(feature, enabled).pipe(
      map(() => [
        FeatureFlagUpdated(feature, enabled),
        new ShowAutoClosableAlert(`Feature ${feature} ${enabled ? 'enabled' : 'disabled'} successfully.`)
      ]),
      catchError(() => [new ShowAutoClosableAlert('Error updating feature flag.')])
    ))
  ), {dispatch: false});

  private generateMetrics(metrics: DashboardWidgets): any {
    return {
      'TotalCases': metrics.total_evictions,
      'IntakeEvictions': metrics.intake_eviction,
      'OpenEvictions': metrics.open_evictions,
      'ClosedEvictions': metrics.closed_evictions,
      'UnpaidInvoices': metrics.unpaid_invoice,
      'PaidInvoices': metrics.paid_invoices,
      'NumberOfClients': metrics.clients
    };
  }

  constructor(
    private actions$: Actions,
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private sessionStorage: SessionStorageService,
    private userService: UserService,
    private featureFlagService: FeatureFlagService,
    private store: Store
  ) {
  }
}
