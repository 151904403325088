import {createAction} from '@ngrx/store';
import {BaseTask, CourtTask} from '@ee/common/models';

export const LOAD_CASE_TASKS = '[Case Tasks] Load Tasks';
export const SET_CASE_TASKS = '[Case Tasks] Set Tasks';
export const SAVE_CASE_TASK = '[Case Tasks] Save Task';
export const UPDATE_CASE_TASK = '[Case Tasks] Update Task';
export const COMPLETE_CASE_TASK = '[Case Tasks] Complete Task';
export const MODIFY_COURT_TASK_JUDGEMENT = '[Case Tasks] Modify Judgement';
export const CASE_TASK_SAVED = '[Case Tasks] Task Saved';
export const COURT_CASE_JUDGEMENT_UPDATED = '[Case Tasks] Judgement Updated';
export const CASE_TASK_DELETED = '[Case Tasks] Case Task Deleted';
export const DELETE_CASE_TASK = '[Case Tasks] Delete Case Task';
export const RESET_COURT_DATE = '[Case Tasks] Reset Court Date';

export const LoadCaseTasks = createAction(
  LOAD_CASE_TASKS,
  (payload: string) => ({payload})
);

export const SetCaseTasks = createAction(
  SET_CASE_TASKS,
  (task: BaseTask[] | undefined) => ({task})
);

export const SaveCaseTask = createAction(
  SAVE_CASE_TASK,
  (caseId: string, task: BaseTask) => ({caseId, task})
);

export const UpdateCaseTask = createAction(
  UPDATE_CASE_TASK,
  (caseId: string, task: BaseTask) => ({caseId, task})
);

export const CompleteCaseTask = createAction(
  COMPLETE_CASE_TASK,
  (caseId: string, task: BaseTask) => ({caseId, task})
);

export const ModifyCourtTaskJudgement = createAction(
  MODIFY_COURT_TASK_JUDGEMENT,
  (caseId: string, task: BaseTask) => ({caseId, task})
);

export const CaseTaskSaved = createAction(
  CASE_TASK_SAVED,
  (caseId: string, task: BaseTask) => ({caseId, task})
);

export const CourtTaskJudgementUpdated = createAction(
  COURT_CASE_JUDGEMENT_UPDATED,
  (caseId: string, task: BaseTask) => ({caseId, task})
);

export const DeleteCaseTask = createAction(
  DELETE_CASE_TASK,
  (caseId: string, taskId: string) => ({caseId, taskId})
);

export const CaseTaskDeleted = createAction(
  CASE_TASK_DELETED,
  (caseId: string, task: BaseTask) => ({caseId, task})
);

export const ResetCourtDate = createAction(
  RESET_COURT_DATE,
  (caseId: string, payload: CourtTask) => ({caseId, payload})
);
