import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MaterialFileInputModule} from 'ngx-custom-material-file-input';
import {ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '../core/core.module';
import {BannerComponent} from './banner.component';
import {MatListModule} from '@angular/material/list';
import {CustomAlertWithLinkComponent} from './custom-alert-with-link.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {OfflineSnackbarComponent} from './offline-snackbar.component';
import {UpgradeDialogComponent} from './upgrade-dialog/upgrade-dialog.component';
import {TaskDialogComponent} from './task-dialog.component';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import {InputDialogComponent} from './input-dialog/input-dialog.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {AddressAutocompleteModule} from '@ee/common/address-autocomplete';
import {DashboardSettingsDialogComponent} from './dashboard-settings-dialog.component';
import {OrganizationSelectorDialogComponent} from './organization-selector-dialog.component';
import {provideEnvironmentNgxMask} from 'ngx-mask';
import {CaseSettingsDialogComponent} from './case-settings-dialog.component';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';

@NgModule({
  providers: [provideEnvironmentNgxMask()],
  declarations: [
    BannerComponent,
    CustomAlertWithLinkComponent,
    OfflineSnackbarComponent,
    UpgradeDialogComponent,
    TaskDialogComponent,
    InputDialogComponent,
    DashboardSettingsDialogComponent,
    OrganizationSelectorDialogComponent,
    CaseSettingsDialogComponent
  ],
  imports: [
    CoreModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MaterialFileInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatListModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatStepperModule,
    NgxMatTimepickerModule,
    MatSlideToggleModule,
    AddressAutocompleteModule,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger
  ],
  exports: [BannerComponent, InputDialogComponent, DashboardSettingsDialogComponent, OrganizationSelectorDialogComponent, CaseSettingsDialogComponent]
})
export class SharedComponentsModule {}
